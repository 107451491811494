/*TODO: make cards dynamically resize with minimum size whilst keeping ratio*/
.image-card {
    .image-thumb, .home{
        object-fit: cover;
        border-radius: 6px;
        padding: 0 2px !important;
    }
}

@media (max-width: 780px) {
    .image-card .image-thumb, .image-card .home{
        object-fit: cover;
        
        height: 360px;
        max-width: 100%;
        padding: 0 2px;
        border-radius: 6px;
    }
}

/*TODO: rework homepage look*/
/*.image-card .home {*/
/*    object-fit: cover;*/
/*    */
/*    height: 500px;*/
/*    width: 500px;*/
/*    padding: 0 2px;*/
/*    border-radius: 6px;*/
/*}*/