.gallery-view {
  z-index: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 75px 10px 10px 10px;

  &__project {
    font-size: 18px;
    font-weight: 100;
    line-height: 25px;
    text-align: center;
    width: 90%;
    margin: auto;
    padding: 25px;

    &__name {
      font-family: "Cinzel", serif;
      line-height: 1;
    }
  }
}

.enlarged-img {
  &__container {
    z-index: 2;
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: rgba(49, 49, 49, 0.98);
    width: 100%;
    height: 100%;
  }

  &__close-btn {
    z-index: 3;
    position: fixed;
    filter: invert(1);
    border: 0;
    right: 50px;
    top: 25px;
    height: 35px;
    cursor: pointer;
  }

  &__image {
    object-fit: contain;
  }

  &__show-description-btn {
    z-index: 3;
    position: fixed;
    filter: invert(1);
    background-color: transparent;
    border: 0;
    font-size: 32px;
    right: 45px;
    top: 70px;
    height: 35px;

    &:hover + .enlarged-img__description__container {
      z-index: 4;
      display: flex;
      max-height: 610px;
      overflow: scroll;
    }
  }

  &__description__container {
    display: none;
    position: fixed;
    background: rgba(49, 49, 49, 0.9);
    font-size: 20px;
    font-weight: lighter;
    line-height: 25px;
    width: 100%;
    height: auto;
    bottom: 0;
  }

  &__description__text {
    text-align: center;
    color: white;
    width: 90%;
    padding: 50px;
  }
}
