.contact {
    text-align: center;
    font-size: 20px;
    margin-top: 70px;
    text-underline-offset: 5px;

    &--text {
        padding: 10px;
    }

    &--email {
        font-weight: 100;
        font-size: large;
    }
}