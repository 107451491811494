.about-text {
    text-align: center;
    line-height: 25px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    &--main {
    padding: 20px;
    font-size: 18px;
    font-weight: 100;
    }
}


.publication-items {
    text-align: left;
    text-underline-offset: 3px;
}