#navbar {
    z-index: 1;
    background: rgb(93, 97, 133);
    color: white;

    height: 70px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    margin-top: 0;
    margin-bottom: 5px;
    box-shadow: 0 2px 2px rgb(37, 71, 69);

    #logo {
        height: 30px;
        padding: 20px;
    }
    
    #menu {
        justify-content: center;
        width: 100%;
        font-family: 'Cinzel', serif;
    
        .selected {
            text-decoration: underline;
            text-underline-offset: 12px;
        }
    
        li {
            padding: 0 25px 0 25px;
            margin: 3px;
            line-height: 3.5;
            list-style: none;
            color: rgb(230, 230, 230);
            font-size: 18px;
            display: inline-flex;
            cursor: pointer;
            text-align: center;
            min-width: fit-content;
    
            &:hover {
                text-decoration: underline;
                text-underline-offset: 12px;
            }
        }
    }
    
    #hamburger-menu--container {
        display: none;
        padding: 20px;
    }
}

@media (min-width: 1300px) {
    .hamburger-menu--icon {
        display: none;
    }
} 

@media (max-width: 1299px) {
    #menu {
        display: none;
    }

    .hamburger-menu--icon {
        filter: brightness(1.5);
        height: 30px;
        width: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    #hamburger-menu--container:checked ~ #menu {
        display: flex;
        flex-direction: column;

        position: absolute;
        max-width: fit-content;
        align-items: center;

        color: rgb(255, 255, 255);
        background: rgb(93, 97, 133);
        padding: 0px 0 10px 0;
        line-height: 3;
        top: 40px;
        right: 0;
        border-radius: 5px 0 5px 5px;
        box-shadow: 0 0 2px black;
    }
}

.project-menu-item {
    position: absolute;
    margin-top: -15px;
    margin-left: -10px;
    font-size: 10px;
    padding: 10px;
}